<template>
    <div id="miles-payments" class="pd-20">
        <a-page-header class="mb-10 pd-0">
            <div slot="title">
                <h1>
                    Pagamentos
                    <span
                        v-html="
                            reportDate(
                                milesCardsPayments.filters.paymentDate.selected
                            )
                        "
                    />
                </h1>
            </div>
            <div slot="extra">
                <downloadExcel
                    v-if="$root.isAdmin()"
                    class="btn btn-default"
                    :header="excelFile.header"
                    :name="excelFile.fileName"
                    :data="excelFile.data"
                    :fields="excelFile.collumns"
                    :footer="excelFile.footer"
                    style="display: inline-block"
                >
                    <!-- <i
                        class="far fa-file-excel cgreen relative"
                        style="font-size: 28px; top: 6px; cursor: pointer; "
                    ></i> -->
                    <img
                        class="c-pointer"
                        src="../../../../assets/images/dashboard/excel.png"
                    />
                </downloadExcel>
            </div>
        </a-page-header>

        <a-row class="haya-panels mb-20" :gutter="20">
            <a-col :span="4">
                <div class="box">
                    <div class="title"></div>
                    <div class="total">
                        {{ milesCardsPayments.pagination.total }}
                    </div>
                    <div class="txt">Total de Pagamentos</div>
                    <div class="footer"></div>
                </div>
            </a-col>

            <a-col :span="4">
                <div class="box">
                    <div class="title"></div>
                    <div class="total">
                        {{ milesCardsPayments.reports.totalPaid }}
                    </div>
                    <div class="txt">Pagamentos Realizados</div>
                    <div class="footer"></div>
                </div>
            </a-col>

            <a-col :span="4">
                <div class="box">
                    <div class="title"></div>
                    <div class="total">
                        {{ milesCardsPayments.reports.totalPending }}
                    </div>
                    <div class="txt">Pagamentos Pendentes</div>
                    <div class="footer"></div>
                </div>
            </a-col>

            <a-col :span="5">
                <div class="box">
                    <div class="title"></div>
                    <div class="total">
                        R$ {{ milesCardsPayments.reports.totalValue }}
                    </div>
                    <div class="txt">Valor total</div>
                    <div class="footer"></div>
                </div>
            </a-col>
        </a-row>

        <a-collapse
            class="travel-filters expandable mt-20 mb-10"
            activeKey="0"
            expandIconPosition="right"
        >
            <a-collapse-panel key="1">
                <template slot="header">
                    <a-icon type="filter" class="mr-5" /> FILTRAR
                </template>

                <a-row
                    class="mt-5 mb-0"
                    :gutter="20"
                    style="margin-left: 0; margin-right: 0"
                >
                    <a-col :span="3">
                        <div class="travel-input">
                            <label class="filled">ID do Cartão</label>
                            <a-input
                                placeholder="Digite"
                                v-model="milesCardsPayments.filters.milesCardId"
                                @change="getMilesCardsPayments()"
                            />
                        </div>
                    </a-col>

                    <a-col :span="3">
                        <div class="travel-input">
                            <label class="filled">ID do Lote</label>
                            <a-input
                                placeholder="Digite"
                                v-model="
                                    milesCardsPayments.filters.transactionId
                                "
                                @change="getMilesCardsPayments()"
                            />
                        </div>
                    </a-col>

                    <a-col :span="6">
                        <div class="travel-input">
                            <label class="filled">Faixa de valores (R$)</label>
                            <a-input-group compact>
                                <a-input
                                    type="number"
                                    style="
                                        width: 45%;
                                        text-align: center;
                                        border-radius: 0;
                                    "
                                    placeholder="Min."
                                    v-model="
                                        milesCardsPayments.filters.price.min
                                    "
                                    @change="getMilesCardsPayments()"
                                />
                                <a-input
                                    style="
                                        width: 10%;
                                        border-left: 0;
                                        pointer-events: none;
                                        backgroundcolor: #fff;
                                        border: 0;
                                    "
                                    placeholder="até"
                                    disabled
                                />
                                <a-input
                                    type="number"
                                    style="
                                        width: 45%;
                                        text-align: center;
                                        border-left: 0;
                                        border-radius: 0;
                                    "
                                    placeholder="Max"
                                    v-model="
                                        milesCardsPayments.filters.price.max
                                    "
                                    @change="getMilesCardsPayments()"
                                />
                            </a-input-group>
                        </div>
                    </a-col>

                    <a-col :span="6">
                        <div class="travel-input">
                            <label class="filled">Vencimentos</label>

                            <a-range-picker
                                v-model="
                                    milesCardsPayments.filters.expiringPeriod
                                        .selected
                                "
                                :format="['DD/MM/YYYY']"
                                :value-format="'YYYY-MM-DD'"
                                @change="getMilesCardsPayments()"
                            />
                        </div>
                    </a-col>

                    <a-col :span="6">
                        <div class="travel-input">
                            <label class="filled">Pagamentos</label>

                            <a-range-picker
                                v-model="
                                    milesCardsPayments.filters.paymentDate
                                        .selected
                                "
                                :format="['DD/MM/YYYY']"
                                :value-format="'YYYY-MM-DD'"
                                @change="getMilesCardsPayments()"
                            />
                        </div>
                    </a-col>

                    <a-col :span="6">
                        <div class="travel-input">
                            <label class="filled">Data compra do Lote</label>

                            <a-range-picker
                                v-model="
                                    milesCardsPayments.filters.period.selected
                                "
                                :format="['DD/MM/YYYY']"
                                :value-format="'YYYY-MM-DD'"
                                @change="getMilesCardsPayments()"
                            />
                        </div>
                    </a-col>

                    <a-col :span="4">
                        <div class="travel-input">
                            <label class="filled">Programas</label>
                            <a-select
                                class="travel-input"
                                placeholder="Selecione a cia aérea"
                                mode="multiple"
                                v-model="
                                    milesCardsPayments.filters.milesPrograms
                                        .selected
                                "
                                show-search
                                style="width: 100%"
                                @change="getMilesCardsPayments()"
                            >
                                <a-select-option
                                    v-for="(item, index) of milesCardsPayments
                                        .filters.milesPrograms.list"
                                    :key="index"
                                    :value="item.value"
                                >
                                    {{ item.label }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </a-col>

                    <a-col :span="4">
                        <div class="travel-input">
                            <label class="filled">Fornecedor</label>
                            <a-auto-complete
                                :data-source="
                                    suppliers.map(
                                        ({ first_name, last_name, id }) => ({
                                            value: id,
                                            text: `${id} - ${first_name} ${last_name}`,
                                        })
                                    )
                                "
                                v-model="milesCardsPayments.filters.supplier"
                                style="width: 100%"
                                placeholder="Buscar fornecedores..."
                                @search="searchSupplier"
                                @change="getMilesCardsPayments()"
                            />
                        </div>
                    </a-col>

                    <a-col :span="4">
                        <div class="travel-input">
                            <label class="filled">Status</label>
                            <a-select
                                class="travel-input"
                                placeholder="Selecione"
                                mode="multiple"
                                v-model="
                                    milesCardsPayments.filters.status.selected
                                "
                                show-search
                                style="width: 100%"
                                @change="getMilesCardsPayments()"
                            >
                                <a-select-option
                                    v-for="(item, index) of milesCardsPayments
                                        .filters.status.list"
                                    :key="index"
                                    :value="item.value"
                                >
                                    {{ item.label }}
                                </a-select-option>
                            </a-select>
                        </div>
                    </a-col>

                    <!-- <a-col :span="6">
                <div class="travel-input">
                    <label class="filled">Fornecedor</label>
                    <a-auto-complete
                        :data-source="
                            suppliers.map(({ first_name, last_name, id }) => ({
                                value: id,
                                text: `${id} - ${first_name} ${last_name}`,
                            }))
                        "
                        v-model="filters.supplier"
                        style="width: 100%"
                        placeholder="Buscar fornecedores..."
                        @search="searchSupplier"
                        @change="getMilesCards()"
                    />
                </div>
            </a-col> -->
                </a-row>
            </a-collapse-panel>
        </a-collapse>

        <a-table
            class="travel-table"
            :columns="columnsPayments"
            :data-source="milesCardsPayments.list"
            :pagination="false"
            :loading="milesCardsPayments.loading"
            :scroll="{ x: 1300 }"
            @change="milesCardsPaymentsTableChange"
        >
            <template slot="batch" slot-scope="record">
                {{ record.transaction_id }}
            </template>

            <template slot="miles_card" slot-scope="record">
                {{ record.id }}
            </template>

            <template slot="airline" slot-scope="record">
                {{ record.airline.name }}
            </template>

            <template slot="miles_program" slot-scope="record">
                {{ record.miles_program }}
            </template>

            <template slot="supplier" slot-scope="record">
                <div
                    style="
                        max-width: 280px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    "
                >
                    {{ record.miles_card.supplier.first_name }}
                    {{ record.miles_card.supplier.last_name }}
                </div>
            </template>

            <template slot="payment_user" slot-scope="record">
                {{ record.first_name }} {{ record.last_name }}
            </template>

            <template slot="user" slot-scope="record">
                {{ record.first_name }} {{ record.last_name }}
            </template>

            <template slot="value" slot-scope="record">
                R$ {{ record }}
            </template>

            <template slot="miles_quantity" slot-scope="record">
                {{ formatInt(record) }}
            </template>

            <template slot="expires_at" slot-scope="record">
                {{ formatDate(record) }}
            </template>

            <template slot="payment_date" slot-scope="record">
                <div v-if="record != '0000-00-00 00:00:00'">
                    {{ formatDateTime(record) }}
                </div>
                <div v-if="record == '0000-00-00 00:00:00'"></div>
            </template>

            <template slot="created" slot-scope="record">
                {{ formatDateTime(record) }}
            </template>

            <div slot="action" slot-scope="record">
                <a-tooltip placement="top" title="Ver pagamentos">
                    <a class="view ml-15 cgreen" @click="view(record)">
                        <a-icon slot="prefix" type="eye-svg" />
                    </a>
                </a-tooltip>
            </div>
            <!-- 
           {{ milesCardsPayments('nnn')}} -->
        </a-table>

        <div class="a-center mt-30" style="padding-bottom: 100px">
            <a-pagination
                show-size-changer
                :page-size-options="pageSizeOptions"
                :default-current="milesCardsPayments.pagination.page"
                :total="milesCardsPayments.pagination.total"
                @change="changeMilesCardsPaymentsPage"
                @showSizeChange="changeMilesCardsPaymentsPageSize"
            >
                <template slot="buildOptionText" slot-scope="props">
                    <span v-if="props.value !== '99999'"
                        >{{ props.value }}/página</span
                    >
                    <span v-if="props.value === '99999'"> Todos</span>
                </template>
            </a-pagination>
        </div>
    </div>
</template>

<script>
import { format, parse } from "date-fns";
import formatThings from "../../../../mixins/general/formatThings.js";
import suppliersMixins from "@/mixins/suppliersMixins";
import useGeneratePDF from "@/composables/generatePDF.js";

export default {
    mixins: [formatThings, suppliersMixins],
    setup() {
        const { generatePDF } = useGeneratePDF();
        return { generatePDF };
    },
    data() {
        return {
            pageSizeOptions: [
                "10",
                "25",
                "50",
                "100",
                "200",
                "500",
                "1000",
                "99999",
            ],
            excelFile: {
                header: "Pagamentos - Cartão de Milhas",
                fileName: "Pagamentos - Cartão de Milhas.xls",
                collumns: {
                    Lote: "transaction_id",
                    Programa: "miles_program",
                    // "ID Cartão": {
                    //     field: "miles_card",
                    //     callback: (card) => {
                    //         return `${card.id}`;
                    //     },
                    // },
                    // Cia: {
                    //     field: "airline",
                    //     callback: (airline) => {
                    //         return `${airline.name}`;
                    //     },
                    // },
                    Fornecedor: {
                        field: "miles_card",
                        callback: (card) => {
                            return `${card.supplier.first_name} ${card.supplier.last_name}`;
                        },
                    },
                    "Nº Parcela": "installment_number",
                    "Valor Fatura": "value",
                    Milhas: "miles_quantity",
                    Vencimento: "expires_at",
                    "Data do pagamento": {
                        field: "payment_date",
                        callback: (date) => {
                            let theDate = date;
                            if (date == "0000-00-00 00:00:00") {
                                theDate = ``;
                            }
                            return `${theDate}`;
                        },
                    },
                    "Pago por": {
                        field: "payment_user",
                        callback: (user) => {
                            let theUser = "";
                            if (user) {
                                theUser = `${user.first_name} ${user.last_name}`;
                            }
                            return `${theUser}`;
                        },
                    },
                    "Criado por": {
                        field: "user",
                        callback: (user) => {
                            return `${user.first_name} ${user.last_name}`;
                        },
                    },
                    "Data Compra do lote": "created",
                },
                data: [],
                footer: [],
            },
            milesCardsPayments: {
                loading: false,
                pagination: {
                    page: 1,
                    perPage: 10,
                    total: 0,
                    totalPages: 0,
                },
                details: {},
                list: [],
                reports: {
                    total: 0,
                    totalPending: 0,
                    totalPaid: 0,
                    totalValue: 0,
                },
                filters: {
                    users: {
                        list: [],
                        selected: [],
                    },
                    status: {
                        list: [
                            {
                                label: "Pago",
                                value: "paid",
                            },
                            {
                                label: "Pendente",
                                value: "pending",
                            },
                        ],
                        selected: [],
                    },
                    airlines: {
                        list: [],
                        selected: [],
                    },
                    milesPrograms: {
                        list: [],
                        selected: [],
                    },

                    period: {
                        selected: [],
                    },
                    expiringPeriod: {
                        selected: [],
                    },
                    paymentDate: {
                        selected: [],
                    },
                    supplier: "",
                    milesBatchId: "",
                    milesCardId: "",
                    transactionid: "",
                    searchTerm: "",
                    price: {
                        min: "",
                        max: "",
                    },
                    order: "desc",
                    orderBy: "created",
                },
            },
            columnsPayments: [
                {
                    title: "Lote",
                    key: "batch",
                    scopedSlots: { customRender: "batch" },
                    width: 90,
                },
                {
                    title: "Programa",
                    key: "miles_program",
                    scopedSlots: { customRender: "miles_program" },
                    width: 110,
                    sorter: true,
                },
                // {
                //     title: "ID Cartão",
                //     dataIndex: "milescard_id",
                //     key: "milescard_id",
                //     sorter: true,
                //     width: 110,
                // },
                // {
                //     title: "Cia",
                //     scopedSlots: { customRender: "airline" },
                //     width: 80,
                // },
                {
                    title: "Fornecedor",
                    key: "supplier",
                    scopedSlots: { customRender: "supplier" },
                    width: 240,
                },
                {
                    title: "Nº Parcela",
                    dataIndex: "installment_number",
                    key: "installment_number",
                },
                {
                    title: "Valor Fatura",
                    dataIndex: "value",
                    key: "value",
                    scopedSlots: { customRender: "value" },
                },
                {
                    title: "Milhas",
                    dataIndex: "miles_quantity",
                    key: "miles_quantity",
                    sorter: true,
                    scopedSlots: { customRender: "miles_quantity" },
                },

                {
                    title: "Vencimento",
                    dataIndex: "expires_at",
                    key: "expires_at",
                    sorter: true,
                    scopedSlots: { customRender: "expires_at" },
                },
                {
                    title: "Data do pagamento",
                    dataIndex: "payment_date",
                    key: "payment_date",
                    sorter: true,
                    scopedSlots: { customRender: "payment_date" },
                },
                {
                    title: "Pago por:",
                    dataIndex: "payment_user",
                    key: "payment_user",
                    scopedSlots: { customRender: "payment_user" },
                },
                {
                    title: "Criado por:",
                    dataIndex: "user",
                    key: "user",
                    scopedSlots: { customRender: "user" },
                },
                {
                    title: "Data Compra do lote",
                    dataIndex: "created",
                    sorter: true,
                    key: "created",
                    scopedSlots: { customRender: "created" },
                },
                {
                    title: "",
                    key: "action",
                    class: "actions",
                    align: "right",
                    fixed: "right",
                    scopedSlots: { customRender: "action" },
                },
            ],
        };
    },
    beforeMount() {
        this.getMilesCardsPayments();

        this.$http
            .get(`/airline/list?page=1&per_page=200&order=ascend&order-by=name`)
            .then(({ data }) => {
                data;

                this.airlines = data.data;

                let airlines = data.data.map((airline) => {
                    return {
                        label: airline.name,
                        value: airline.id,
                    };
                });

                let milesPrograms = [];

                data.data.forEach((airline) => {
                    airline.miles_programs.forEach((program) => {
                        milesPrograms.push({
                            label: program.name,
                            value: program.name,
                        });
                    });
                });

                this.milesCardsPayments.filters.airlines.list = airlines;
                this.milesCardsPayments.filters.milesPrograms.list =
                    milesPrograms;
            })
            .catch(({ response }) => {
                response;
            });
    },
    methods: {
        reportDate(date) {
            let theDate = "";
            if (date.length > 0) {
                theDate = `<span style="font-size: 18px; color: #999;"> - ${this.formatDate(
                    date[0]
                )} a ${this.formatDate(date[1])} </span>`;
            }
            return theDate;
        },
        formatDateTime(date) {
            let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
            return format(theDate, "dd/MM/yyyy HH:mm");
        },
        view(payment) {
            this.$router.push(
                `/miles-finances/payments/view/${payment.miles_card.id}`
            );
        },
        changeMilesCardsPaymentsPage(current) {
            this.milesCardsPayments.pagination.page = current;
            this.getMilesCardsPayments();
        },
        changeMilesCardsPaymentsPageSize(current, pageSize) {
            this.milesCardsPayments.pagination.page = current;
            this.milesCardsPayments.pagination.perPage = pageSize;
            this.getMilesCardsPayments();
        },
        onMilesCardsPaymentsPageChange({ current }) {
            this.getMilesCardsPayments(current);
        },
        milesCardsPaymentsTableChange(pagination, filters, sorter) {
            console.log("sorter s", sorter, pagination, filters);
            this.milesCardsPayments.filters.order =
                sorter.order != undefined ? sorter.order : "desc";
            this.milesCardsPayments.filters.orderBy =
                sorter.column != undefined ? sorter.column.key : "created";
            this.getMilesCardsPayments();
        },
        getMilesCardsPayments(samePage) {
            let filters = "";

            this.milesCardsPayments.loading = true;

            if (this.milesCardsPayments.filters.searchTerm) {
                filters += `&s=${this.milesCardsPayments.filters.searchTerm}`;
            }

            if (this.milesCardsPayments.filters.transactionId) {
                filters += `&transaction_id=${this.milesCardsPayments.filters.transactionId}`;
            }

            if (this.milesCardsPayments.filters.milesCardId) {
                filters += `&milescard_id=${this.milesCardsPayments.filters.milesCardId}`;
            }

            if (this.milesCardsPayments.filters.status.selected.length > 0) {
                filters += `&status=${this.milesCardsPayments.filters.status.selected}`;
            }

            if (this.milesCardsPayments.filters.airlines.selected.length > 0) {
                filters += `&airline_id=${this.milesCardsPayments.filters.airlines.selected}`;
            }

            if (
                this.milesCardsPayments.filters.milesPrograms.selected.length >
                0
            ) {
                filters += `&miles_program=${this.milesCardsPayments.filters.milesPrograms.selected}`;
            }

            if (this.milesCardsPayments.filters.users.selected.length > 0) {
                filters += `&user_id=${this.milesCardsPayments.filters.users.selected}`;
            }

            if (
                this.milesCardsPayments.filters.expiringPeriod.selected.length >
                0
            ) {
                filters += `&expires_at=${this.milesCardsPayments.filters.expiringPeriod.selected}`;
            }

            if (this.milesCardsPayments.filters.supplier) {
                filters += `&supplier=${this.milesCardsPayments.filters.supplier}`;
            }

            if (
                this.milesCardsPayments.filters.paymentDate.selected.length > 0
            ) {
                filters += `&payment_date=${this.milesCardsPayments.filters.paymentDate.selected}`;
            }

            if (this.milesCardsPayments.filters.period.selected.length > 0) {
                filters += `&period=${this.milesCardsPayments.filters.period.selected[0]}|${this.milesCardsPayments.filters.period.selected[1]}`;
            }

            if (
                this.milesCardsPayments.filters.price.min ||
                this.milesCardsPayments.filters.price.max
            ) {
                let min = this.milesCardsPayments.filters.price.min;
                let max = this.milesCardsPayments.filters.price.max;

                if (min) {
                    if (!max) {
                        max = "500000";
                    }
                }

                if (max) {
                    if (!min) {
                        min = "0";
                    }
                }

                filters += `&value=${min}-${max}`;
            }

            filters += `&order=${this.milesCardsPayments.filters.order}&order-by=${this.milesCardsPayments.filters.orderBy}`;

            this.$http
                .get(
                    `/payment/list?page=${this.milesCardsPayments.pagination.page}&per_page=${this.milesCardsPayments.pagination.perPage}${filters}`
                )
                .then(({ data }) => {
                    if (!samePage) {
                        this.milesCardsPayments.pagination.page = 1;
                    }
                    this.milesCardsPayments.pagination.total = data.meta.total;
                    this.milesCardsPayments.pagination.totalPages =
                        data.meta.total_pages;
                    this.milesCardsPayments.list = data.data;
                    this.milesCardsPayments.loading = false;
                    this.milesCardsPayments.reports.totalValue =
                        data.totalValue;
                    this.milesCardsPayments.reports.totalPending =
                        data.totalPending;
                    this.milesCardsPayments.reports.totalPaid = data.totalPaid;

                    this.excelFile.data = data.data;

                    this.excelFile.footer = [];

                    this.excelFile.footer.push(" ");

                    this.excelFile.footer.push(
                        `TOTAL DE PAGAMENTOS: ${data.meta.total}`
                    );

                    this.excelFile.footer.push(
                        `TOTAL PAGAMENTOS EFETUADOS: ${data.totalPaid}`
                    );

                    this.excelFile.footer.push(
                        `TOTAL PAGAMENTOS PENDENTES: ${data.totalPending}`
                    );

                    this.excelFile.footer.push(
                        `VALOR TOTAL: R$ ${data.totalValue}`
                    );
                })
                .catch(({ response }) => {
                    this.milesCardsPayments.loading = false;
                    this.milesCardsPayments.list = [];
                    this.milesCardsPayments.pagination.total =
                        response.data.meta.total;
                    this.milesCardsPayments.pagination.totalPages =
                        response.data.meta.total_pages;
                    this.milesCardsPayments.reports.totalValue =
                        response.data.totalValue;
                    this.milesCardsPayments.reports.totalPending =
                        response.data.totalPending;
                    this.milesCardsPayments.reports.totalPaid =
                        response.data.totalPaid;
                });
        },
    },
};
</script>

<style lang="scss" scoped></style>
